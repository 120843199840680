import { IdTipoUsuario } from "./entities/TipoUsuario";

export interface UserAuthProps {
  cod: string;
  senha: string;
  tipo: number;
}

export interface UserAuthTypeProps {
  default: UserAuthProps;
  cliente: UserAuthProps;
  representante: UserAuthProps;
  gestor: UserAuthProps;
  agente: UserAuthProps;
}

export const userIsActive: UserAuthTypeProps = {
  default: {
    cod: ``,
    senha: ``,
    tipo: IdTipoUsuario.CLIENTE,
  },
  cliente: {
    cod: `25.008.157/0001-94`,
    senha: `26032016Br.`,
    tipo: IdTipoUsuario.CLIENTE,
  },
  representante: {
    //cod: `001003`,
    //senha: `362510`,
    //REPRESENTANTE PROD
    //cod: `000826`,
    //senha: `548982`,
    //Relatorio faturamento por email (yone)
    //cod: `000634`,
    //senha: `187906`,
    //cod: `000911`,
    //senha: `754147`,
    cod: `000713`,
    senha: `987654`,
    tipo: IdTipoUsuario.REPRESENTANTE,
  },
  agente: {
    cod: `000911`, //'000473`,
    senha: `754147`, //'336317`,
    tipo: IdTipoUsuario.AGENTE,
    //cod: "000966",
    //senha: "352195",
  },
  gestor: {
    cod: `001029`, //'001029', //jessica -- //'000635',yone //'000946', //  --0208`2
    senha: `401862`, //'401862',           //'187906',       '042102', `/
    tipo: IdTipoUsuario.SUPERVISOR,
  },
} as UserAuthTypeProps;

export interface envURLProps {
  AUTH: boolean; //Realiza login automático *USAR SOMENTE NO AMBIENTE DESENVOLVIMENTO*
  USER: UserAuthProps;
  API_URL: string;
  ASSETS_URL: string;
}

export interface envURLTypeProps {
  production: envURLProps;
  development: envURLProps;
  homologacao: envURLProps;
}

const envURL: envURLTypeProps = {
  /*AMBIENTE: DEV-LOCAL WEB*/
  development: {
    AUTH: true, //Realiza login automático *USAR SOMENTE NO AMBIENTE DESENVOLVIMENTO*
    USER: userIsActive.agente,
    API_URL: `http://localhost:3333`,
    ASSETS_URL: `https://teste-b2b.ferrarinet.com.br/assets", 'http://localhost:3336/assets`,
  },
  /*AMBIENTE: DEV-HOMOLOG WEB*/
  homologacao: {
    AUTH: false,
    USER: userIsActive.default,
    API_URL: `https://teste-b2b.ferrarinet.com.br/api`,
    ASSETS_URL: `https://teste-b2b.ferrarinet.com.br/assets`,
  },
  /*AMBIENTE: PRODUÇÃO WEB*/
  production: {
    AUTH: false,
    USER: userIsActive.default,
    API_URL: `https://b2b.ferrarinet.com.br/api`,
    ASSETS_URL: `https://b2b.ferrarinet.com.br/assets`,
  },
} as envURLTypeProps;

//nvm use 10.15.1

const ENV = envURL.homologacao; //"development" | "production" | "homologacao"

export default ENV;
