const version = "v2.0.2";

/* Desenvolvimento, melhorias, correções e ajustes */

/* Versão: v2.0.2
 * Data: 2024-05-21
 * Developer: Josuel Lopes - HF
 * Solicitante: Douglas Chagas
 * Projeto: Ajustes de funcionalidades
 * - Pedido:
 *                  - Criando nova tela pedido para não realizar calculo buscando do Protheus
 *                  - Calculo de valor, entrega e impostos buscando do Protheus só deve ser realizado ao clicar no botão "Calcular"
 * - Tratativa Error:
 *                  - Realizar tratativa do retorno error quando carrega planilha excel
 * /

/* Versão: v2.0.1f
 * Data: 2024-04-17
 * Developer: Josuel Lopes - HF
 * Solicitante: Douglas Chagas / Simone
 * Projeto: Ajustes de funcionalidades
 * - Registro Contato:
 *                  - Validação ao atualizar registro contato sincronização
 * - Orçamento PDF:
 *                  - Paginação da lista produtos no orçamento ao gerar o PDF
 *                  - Correção no envio do valor porcentagem desconto
 * /

/* Versão: v2.0.1e
 * Data: 2024-04-10
 * Developer: Josuel Lopes - HF
 * Solicitante: Douglas Chagas / Simone
 * Projeto: Ajustes de funcionalidades
 * - Registro Contato:
 *                  - Validação ao atualizar registro contato por código e loja
 * /

/* Versão: v2.0.1f
 * Data: 2024-02-26
 * Developer: Josuel Lopes - HF
 * Solicitante: Douglas Chagas / Manuel Souza
 * Projeto: Ajustes de funcionalidades
 * - Registro Contato:
 *                  - Salvar e atualizar data contato em tempo real (on-line)
 *                  - Ajustar para exibir data contato em tempo de execução
 * - Orçamento PDF:
 *                  - Ajuste para exibir calculo de impostos retornando do Protheus
 * /

/* Versão: v2.0.1e
 * Data: 2024-01-30
 * Developer: Josuel Lopes - HF
 * Solicitante: Douglas Chagas / Manuel Souza
 * Projeto: Ajustes de funcionalidades
 * - Orçamento PDF:
 *                  - Remover ano apresentado na frente nome empresa
 *                  - Quebra de linha na descrição do produto
 *                  - Ajustes para exibir data e horário pt-BR
 * - Lista de Cliente e Lista de Prospect:
 *                  - Adicionado atalho para acessar "Histórico de contato"
 * - Registro Contato:
 *                  - Listar todos os grupos
 * - Histórico de Contato:
 *                  - Adicionado cabeçalho com Nome, Código e Loja
 *                  - Adicionado coluna para exibir concorrente
 * - Confirmação de Pedido:
 *                  - Adicionado botão para exibir itens do pedido
 *                  - Lista dos itens do pedido com Código, descrição e quantidade
 * - Planilha Excel:
 *                  - Ajustes no download
 *                  - Ajustes no upload
 * - Editar MPP:
 *                  - Ajustes e correção para editar o MPP em tempo de execução
 */

/* Versão: v2.0.1d
 * Data: 2024-01-24
 * Developer: Josuel Lopes - HF
 * Solicitante: Douglas Chagas / Manuel Souza
 * Projeto: Ajustes de funcionalidades
 * - Lista Cliente - Ajustes tabela responsivo para telas menores
 * - Orçamento PDF - Exibir produtos, envio de anexo do PDF na solicitação e-mail
 */

/* Versão: v2.0.1c
 * Data: 2023-12-22
 * Developer: Josuel Lopes - HF
 * Solicitante: Douglas Chagas / Manuel Souza
 * Projeto: Ajustes de funcionalidades
 * - Cadastro Cliente - Adicionar campos adicionais solicitados
 * - Cliente - Lista cliente permitir filtro e ordenação por data próximo contato
 */

/* Versão: v2.0.1b
 * Data: 2023-12-04
 * Developer: Josuel Lopes - HF
 * Solicitante: Douglas Chagas / Otniel
 * Projeto: Ajustes de funcionalidades
 * - Agenda - Histórico de contato => Adicionar campos dados retornados na API
 */

/* Versão: v2.0.1a
 * Data: 2023-12-04
 * Developer: Josuel Lopes - HF
 * Solicitante: Douglas Chagas / Otniel
 * Projeto: Ajustes de funcionalidades
 * - Agenda - Cadastro de contato: campos concorrente, grupo com selects não obrigatório selecionando a opção "Selecione..."
 * - Agenda - Cadastro de contato: campos obrigatórios concorrentes e inatividade
 * - Pedidos: Ajustes na função de atualização dos itens para calcular impostos direto no PROTHEUS
 * - Pedidos: Ajustes para calcular automaticamente impostos ao adicionar desconto no item
 * - Pedidos: Ajustes para enviar para PROTHEUS somente itens com quantidade maior que zero
 */

/* Versão: v2.0.1
 * Data: 2023-06
 * Developer: Josuel Lopes - HF
 * Solicitante: Douglas Chagas / Otniel
 * Projeto: Novas funcionalidades
 * - Relatório de faturamento requisição via API Protheus
 * - Cadastro de prospect com formulário e lista
 * - Agenda de contato para prospects e clientes
 * - Lista Cliente: Ajustes nos botões para dropdown
 * - Cadastro cliente: Unificando os campos de e-mail
 * - Minha Conta - Fatura: Filtrar a listar por Agente e Representante
 * - Controle de Coleta: Criando a lista de coletas por Agente e Representante
 * - Editar sugestão lista de produtos MPP: Criando a lista de produtos com possibilidade de editar
 * - Pedidos: Adicionando botão para informar motivo pela qual não efetuou a compra de um produto ou pelo grupo de produtos
 * */

/* Versão: v2.0.0
 * Data: 2022-06
 * Developer: Josuel Lopes - HF
 * Solicitante: Douglas Chagas / Otniel
 * Projeto: Perfil Supervisor, Orçamento, Agrupar Produtos, Envio e-mail, Ajustes Campos
 * - Criar versionamento para controle de versão da aplicação;
 * - Criar funcionalidades de orçamentos;
 * - Criar perfil de login "Supervisor";
 * - Perfil "Supervisor": Lista de orçamentos opção de "Aprova" ou "Reprovar"
 * - Criar funcionalidade de solicitação envio de email Nota/Pedido via Protheus;
 * - Criar funcionalidade de solicitação envio de email Orçamento via B2b;
 * - Adicionar campos CNPJ e Nome transportadora "Modal de confirmação pedido" selecionar transportadora;
 * - Adicionar campos E-mail e DDD transportadora "Modal de confirmação pedido" para nova transportadora;
 * - Adicionar colunas CNPJ e Nome transportadora "Minha Conta / Meus pedidos";
 * - Remover campo número nos cadastro (no Protheus é salvo junto com endereço);
 * - Lista Pedidos: Cria agrupamento por grupo de produtos, opção de expandir e agrupar;
 */

/* DEV - Ultima atualização 2021-10-20 - JL*/
/* 2021-10-20 - Remoção de calculo de frete - solicitado por Douglas Chagas */
/* 2022-02-03 - Remoção Gráfico Cliente e botão campanha compre ganhe - solicitado por Douglas Chagas */
/* 2022-05-31 - Projeto Adicionar novas funcionalidades,
    - Adicionar CNPJ Select Transportadora;
    - Adicionar campo E-mail para nova transportadora;
    - Remover campo número no cadastro;
    - Lista produtos criar botão expandir por categoria;
    - Criar orçamento de pedidos com solicitação de aprovação;
    *** solicitado por Douglas Chagas ***
*/

export default version;
