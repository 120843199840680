import { hot } from "react-hot-loader/root";
import React from "react";
import { Switch, Route, Redirect, RouteProps } from "react-router-dom";
import Swal from "sweetalert2";

import Home from "./pages/Home";
import Login from "./pages/Login";
import RecuperacaoSenha from "./pages/RecuperacaoSenha";
import Clientes from "./pages/Clientes";
import Supervisor from "./pages/Supervisor"; //JL-HF_2022-06
import Pedido from "./pages/Pedido";
import PedidoNew from "./pages/PedidoNew"; //JL-HF_2022-06
import PedidosResgate from "./pages/PedidoResgate";
import PedidoUpload from "./pages/PedidoUpload";
import Pedidos from "./pages/MinhaConta/Pedidos";
import Orcamentos from "./pages/MinhaConta/Orcamentos"; //JL-HF_2022-06
//import OrcamentoDetail from './components/OrcamentoDetail'; //JL-HF_2022-06
import Faturas from "./pages/MinhaConta/Faturas";
import DadosCadastrais from "./pages/MinhaConta/DadosCadastrais";
import Enderecos from "./pages/MinhaConta/Enderecos";
import AlterarSenha from "./pages/MinhaConta/AlterarSenha";
import { useAppContext } from "./contexts/app";
import { IdTipoUsuario } from "./entities/TipoUsuario"; //JL-HF_2022-06

import PedidoEditaMPP from "./pages/PedidoEditarMPP"; //JL-HF_2023-07
import Prospects from "./pages/Prospects"; //JL-HF_2023-07
import ProspectsCadastro from "./pages/Prospects//Cadastro"; //JL-HF_2023-07
import ConroleColetas from "./pages/MinhaConta/ControleColetas";
//import HeaderCliente from './components/HeaderCliente';
//import GraphicMetaClient from './components/HeaderCliente/GraphicMeta';

const ProtectedRoute: React.FC<RouteProps> = ({ children, path, ...rest }) => {
  const { logged, user } = useAppContext();

  return (
    <Route
      path={path}
      {...rest}
      render={({ location }) => {
        if (Swal.isVisible() && Swal.isLoading()) {
          Swal.close();
        }

        if (!logged && (path === "/login" || path === "/recuperarSenha")) {
          return <>{children}</>;
        }

        if (!logged && path !== "/login") {
          return <Redirect to={{ pathname: "/login" }} />;
        }

        if (logged && (path === "/login" || path === "/recuperarSenha")) {
          if (
            (user?.tipo.id === IdTipoUsuario.REPRESENTANTE ||
              user?.tipo.id === IdTipoUsuario.AGENTE) &&
            user.revendedor
          ) {
            return <Redirect to={{ pathname: "/" }} />;
          }

          //JL-HF_2021-06-24 ##ADD user?.tipo.nome === 'AGENTE'
          if (
            (user?.tipo.id === IdTipoUsuario.REPRESENTANTE ||
              user?.tipo.id === IdTipoUsuario.AGENTE) &&
            !user.revendedor
          ) {
            return <Redirect to={{ pathname: "/clientes" }} />;
          }

          //JL-HF_2021-06-24 ##ADD user?.tipo.nome === 'AGENTE'
          if (user?.tipo.id === IdTipoUsuario.SUPERVISOR && !user.revendedor) {
            return <Redirect to={{ pathname: "/supervisor" }} />;
          }

          return <Redirect to={{ pathname: "/" }} />;
        }

        if (
          logged &&
          user?.tipo.id === IdTipoUsuario.CLIENTE &&
          path === "/clientes"
        ) {
          return <Redirect to={{ pathname: "/" }} />;
        }

        if (
          user?.tipo.id === IdTipoUsuario.SUPERVISOR &&
          path !== "/supervisor"
        ) {
          return <Redirect to={{ pathname: "/supervisor" }} />;
        }

        return (
          <>
            {/* {user?.tipo.nome === 'REPRESENTANTE' && user.revendedor && path !== '/clientes' && <HeaderCliente />} */}
            {(user?.tipo.id === IdTipoUsuario.REPRESENTANTE ||
              user?.tipo.id === IdTipoUsuario.AGENTE) &&
              user.revendedor &&
              path !== "/clientes"}
            {children}
          </>
        );
      }}
    />
  );
};

const Routes = () => {
  const { loading } = useAppContext();

  if (loading) {
    return <div></div>;
  }

  return (
    <Switch>
      <ProtectedRoute path="/login" exact>
        <Login />
      </ProtectedRoute>

      <ProtectedRoute path="/recuperarSenha" exact>
        <RecuperacaoSenha />
      </ProtectedRoute>

      <ProtectedRoute path="/" exact>
        <Home />
      </ProtectedRoute>

      <ProtectedRoute path="/clientes" exact>
        <Clientes />
      </ProtectedRoute>

      <ProtectedRoute path="/supervisor" exact>
        <Supervisor />
      </ProtectedRoute>

      <ProtectedRoute path="/pedido/mpp" exact>
        <Pedido />
      </ProtectedRoute>

      <ProtectedRoute path="/pedido/mpp/new" exact>
        <PedidoNew />
      </ProtectedRoute>

      {/*Editar Lista de MPP - JL_HF_2023-09 */}
      <ProtectedRoute path="/pedido/edita/mpp" exact>
        <PedidoEditaMPP />
      </ProtectedRoute>

      <ProtectedRoute path="/prospects" exact>
        <Prospects />
      </ProtectedRoute>

      <ProtectedRoute path="/prospects/cadastro" exact>
        <ProspectsCadastro />
      </ProtectedRoute>

      {/*Campanha Compre Ganhe - JL_HF_2021-04 */}
      <ProtectedRoute path="/pedido/resgate" exact>
        <PedidosResgate />
      </ProtectedRoute>

      <ProtectedRoute path="/pedido/upload" exact>
        <PedidoUpload />
      </ProtectedRoute>

      <ProtectedRoute path="/pedido/planilha" exact>
        <Pedido />
      </ProtectedRoute>

      <ProtectedRoute path="/pedido/ofertas" exact>
        <Pedido somenteOfertas={true} />
      </ProtectedRoute>

      <ProtectedRoute path="/minhaconta" exact>
        <Redirect to="minhaconta/pedidos" />
      </ProtectedRoute>

      <ProtectedRoute path="/minhaconta/pedidos" exact>
        <Pedidos />
      </ProtectedRoute>

      {/* JL-HF_2022-06 */}
      <ProtectedRoute path="/minhaconta/orcamentos" exact>
        <Orcamentos />
      </ProtectedRoute>

      {/*Lista Controle de coletas FOB - JL_HF_2023-09 */}
      <ProtectedRoute path="/minhaconta/controlecoletas" exact>
        <ConroleColetas />
      </ProtectedRoute>

      <ProtectedRoute path="/minhaconta/faturas" exact>
        <Faturas />
      </ProtectedRoute>

      <ProtectedRoute path="/minhaconta/dadoscadastrais" exact>
        <DadosCadastrais />
      </ProtectedRoute>

      <ProtectedRoute path="/minhaconta/enderecos" exact>
        <Enderecos />
      </ProtectedRoute>

      <ProtectedRoute path="/minhaconta/alterarsenha" exact>
        <AlterarSenha />
      </ProtectedRoute>

      <Route path="*" exact>
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};

export default hot(Routes);
