import React, { useState, useEffect, useRef, MouseEvent } from "react";
import { FiPlusCircle, FiSend, FiSave, FiGift, FiTrash } from "react-icons/fi";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Prompt, useHistory, useLocation } from "react-router-dom";
import { AxiosResponse } from "axios";
import { IoIosCalculator } from "react-icons/io";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";

import { ItemPedido, Produto } from "../../typings";
import PedidoResumo from "../../components/PedidoResumo";
import OrcamentoResumo from "../../components/OrcamentoResumo";
import ProdutoSearch from "../../components/ProdutoSearch";
import {
  fetchMPP,
  updateItems,
  fetchPromocoes,
  fetchCondicoesPagamento,
  fetchGruposProdutosPorCNPJ,
  fetchTransportadoras,
  fetchHistoricoCompraProduto,
  gravarOrcamentoInteracao,
} from "../../services/api";
import { useAppContext } from "../../contexts/app";
import ModalSaida from "../../components/ModalSaida";
import ProdutoHistory from "../../components/ProdutoHistory";
import ProdutoDetail from "../../components/ProdutoDetail";
import ItemPedidoDetail from "../../components/ItemPedidoDetail";
import ItemPedidoDesconto from "../../components/ItemPedidoDesconto";
import PedidoMessageFrete from "../../components/PedidoMessageFrete";
import { IdTipoUsuario } from "../../entities/TipoUsuario";

import RowPedido from "./RowPedido";
import ButtonComentarioPedido from "./Comentario";

interface ItemsPlanilha {
  ultimaCondicaoPagamento: string;
  produtos: ItemPedido[];
}

interface ItemsCarregaQtd {
  carregaQtd: boolean;
}
interface IsOrcamentoProps {
  isOrcamento: boolean;
}

interface GrupoProdutos {
  produtos: ItemPedido[];
  codigogrupo: string;
  descrigrupo: string;
}

//JL-HF_2022-11
interface GrupoProdutosPorCNPJ {
  codigo: string;
  descri_grupo: string;
  grupo: string;
  loja: string;
  nome: string;
}

const Pedido: React.FC<{ somenteOfertas?: boolean }> = ({
  somenteOfertas = false,
}) => {
  const [condicoesPagamento, setCondicoesPagamento] = useState<
    { codigo: string; descricao: string }[]
  >([]);
  const [condicaoPagamento, setCondicaoPagamento] = useState("");
  const [items, setItems] = useState<ItemPedido[]>([]);
  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const { user, showLoader, hideLoader } = useAppContext(); //setLoadSaldoMeta
  const confirmLeave = useRef(false);
  const [grupoProdutos, setGrupoProdutos] = useState<GrupoProdutos[]>([]);

  const history = useHistory();

  /* JL_2021-09-23 - Carregar Qtd. quando upload planilha excel */
  const { state } = useLocation<{
    itemsPlanilha?: ItemsPlanilha;
    carregaQtd: ItemsCarregaQtd;
    orcamentoPedido?: any;
    isOrcamento?: IsOrcamentoProps;
  }>();
  const [carregarQtd] = useState<boolean>(state?.carregaQtd ? true : false); //JL_2021-09-23
  //let group = "0";

  const [isExpand, setIsExpand] = useState(
    (state?.isOrcamento && state?.orcamentoPedido?.produtos[0].codigogrupo) ||
      (state?.itemsPlanilha && state?.itemsPlanilha.produtos[0].codigogrupo) ||
      "0"
  ); //JL-HF_06-2022
  const [isOpen, setIsOpen] = useState(
    state?.isOrcamento || state?.itemsPlanilha ? true : false
  ); //JL-HF_06-2022
  const [isDisabledConfirmaPedido, setIsDisabledConfirmaPedido] = useState(
    false
  );
  const [isPercentualDesconto, setIsPercentualDesconto] = useState(false);
  //const [orcamentoHistorico, setOrcamentoHistorico] = useState(0.0);

  useEffect(() => {
    async function fetchInitialData() {
      try {
        const loader = showLoader(
          "Por favor aguarde. Esta tarefa pode demorar vários segundos. Carregando sugestão de pedido."
        );

        const promCondicoesPagamento = fetchCondicoesPagamento();

        let promItems: any = (await (somenteOfertas && !state))
          ? await fetchPromocoes({
              calcularImpostos: "false",
              calcularEntregas: "true",
              calcularQuantidadeMes: "true",
            })
          : await fetchMPP({
              calcularImpostos: "false",
              calcularEntregas: "true",
            });

        if (state?.itemsPlanilha && !state.isOrcamento) {
          if (
            state?.itemsPlanilha.ultimaCondicaoPagamento &&
            state?.itemsPlanilha.ultimaCondicaoPagamento !== condicaoPagamento
          ) {
            setCondicaoPagamento(state?.itemsPlanilha.ultimaCondicaoPagamento);
          }

          const merged = transformItems(state.itemsPlanilha.produtos);

          /*console.log('>>PLANILHA');
          console.log(merged);*/
          setItems(merged);

          promItems = null;
        }

        /**JL-HF_2022-06 */
        if (state?.orcamentoPedido && state?.isOrcamento) {
          if (
            state?.orcamentoPedido?.ultimaCondicaoPagamento &&
            state?.orcamentoPedido.ultimaCondicaoPagamento !== condicaoPagamento
          ) {
            setCondicaoPagamento(
              state?.orcamentoPedido.ultimaCondicaoPagamento
            );
          }

          const merged = await transformItems(state?.orcamentoPedido.produtos);

          setItems(merged);
          /*console.log('>>ORCAMENTO');
          console.log(merged);*/

          setIsDisabledConfirmaPedido(
            isPercentualDesconto &&
              state?.isOrcamento &&
              state.orcamentoPedido?.status !== 4
          ); //STATUS = 4 (APROVADO)

          promItems = null;
        }
        /**JL-HF_2022-06 END */

        const [resCondicoesPagamento, resItems] = await Promise.all([
          promCondicoesPagamento,
          promItems,
        ]);

        if (resCondicoesPagamento && resCondicoesPagamento.data) {
          setCondicoesPagamento(resCondicoesPagamento.data);

          if (condicaoPagamento === "") {
            setCondicaoPagamento(resCondicoesPagamento.data[0].codigo);
          }
        }

        if (resItems && resItems.data) {
          if (
            resItems.data.ultimaCondicaoPagamento &&
            resItems.data.ultimaCondicaoPagamento !== condicaoPagamento
          ) {
            setCondicaoPagamento(resItems.data.ultimaCondicaoPagamento);
          }

          const merged = transformItems(resItems.data.produtos);

          setItems(merged);
        }

        hideLoader(loader);
      } catch (error) {
        Swal.fire({
          icon: "warning",
          title: "Erro",
          text:
            "Ocorreu um erro no processamento da requisição. Por favor, tente novamente mais tarde.",
          allowEscapeKey: false,
          allowOutsideClick: false,
          confirmButtonText: "OK",
        });

        console.log(error);
      }
    }

    if (items.length === 0 && condicoesPagamento.length === 0) {
      fetchInitialData();
    }
  }, [
    condicaoPagamento,
    condicoesPagamento.length,
    grupoProdutos,
    hideLoader,
    isDisabledConfirmaPedido,
    isOpen,
    isPercentualDesconto,
    items,
    items.length,
    showLoader,
    somenteOfertas,
    state,
    transformItems,
    user,
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function transformItems(itens: ItemPedido[]) {
    let quantidadeComprar = 0;
    //let valorTotalSemImpostos = 0;

    const merged = itens.map((item) => {
      console.log(item.percentualDesconto);
      const percentualDesconto = item.percentualDesconto || 0;
      //const desconto = (item.precoPor / 100) * percentualDesconto; //Corrido retorno calculo no protheus

      /* JL_2021-09-23 - Carrega Qtd. quando upload da planilha Excel */
      // quantidadeComprar = carregarQtd || item._keepQuantidadeComprar === undefined ? item.quantidadeComprar : 0;
      quantidadeComprar =
        carregarQtd && item.quantidadeComprar > 0 ? item.quantidadeComprar : 0;

      if (item._keepQuantidadeComprar) {
        quantidadeComprar = item.quantidadeComprar;
      }

      let precoItemComDesconto = item.precoPor; //- desconto; //Corrido retorno calculo no protheus
      let precoTotalSemImpostos = precoItemComDesconto * quantidadeComprar;
      let precoTotalComImpostos = precoItemComDesconto * quantidadeComprar;

      if (item.impostoCalculado && quantidadeComprar > 0) {
        precoTotalComImpostos =
          precoItemComDesconto * quantidadeComprar + item.ipi + item.st;
      }

      //valorTotalSemImpostos += precoTotalSemImpostos;

      //JL-HF_2022-10
      if (!isPercentualDesconto && percentualDesconto > 0) {
        setIsPercentualDesconto(true);
      }

      return {
        ...item,
        quantidadeComprar,
        precoPorFormatado: precoItemComDesconto.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        }),
        precoTotalSemImpostos,
        precoTotalComImpostos,
      };
    });

    // return merged;
    /**JL-HF_ 06-2022 */
    const mergedOrderCod = merged.sort((a, b) => {
      return a["codigogrupo"] > b["codigogrupo"] ? 1 : -1;
    });

    /*if (orcamentoHistorico === 0 && state.isOrcamento) {
      setOrcamentoHistorico(valorTotalSemImpostos);
    }

    if (state.isOrcamento && orcamentoHistorico !== valorTotalSemImpostos) {
      setIsDisabledConfirmaPedido(true);
    } else if (state.isOrcamento && orcamentoHistorico === valorTotalSemImpostos) {
      setIsDisabledConfirmaPedido(false);
    }*/

    //
    //const getGroupProdutos =
    gerarGroupProdutos(mergedOrderCod);

    ///setGrupoProdutos(getGroupProdutos);

    return mergedOrderCod;
  }

  async function gerarGroupProdutos(getProdutos: any) {
    let isInTheGroup = false;
    const formatGroup: GrupoProdutos[] = [] as GrupoProdutos[];

    getProdutos.map((item: GrupoProdutos) => {
      isInTheGroup =
        formatGroup.filter((i) => i.codigogrupo === item.codigogrupo).length >
        0;

      if (!isInTheGroup) {
        formatGroup.push({
          produtos: getProdutos.filter(
            (i: ItemPedido) => i.codigogrupo === item.codigogrupo
          ),
          codigogrupo: item.codigogrupo,
          descrigrupo: item.descrigrupo,
        });
      }
      return null;
    });

    if (!state?.itemsPlanilha && !state?.isOrcamento) {
      const gruposProdutosPorCNPJ: GrupoProdutosPorCNPJ[] = await fetchGruposProdutosPorCNPJ(
        {
          cnpj: user!.revendedor.cnpj,
        }
      ); //'35524467000115' });

      gruposProdutosPorCNPJ.map((g: any) => {
        isInTheGroup =
          formatGroup.filter((i) => i.codigogrupo === g.grupo).length > 0;
        if (!isInTheGroup) {
          formatGroup.push({
            produtos: [] as ItemPedido[],
            codigogrupo: g.grupo,
            descrigrupo: g.descri_grupo,
          });
        }
        return null;
      });
      //TESTE
      /*isInTheGroup = formatGroup.filter((i) => i.codigogrupo === '029').length > 0;
      if (!isInTheGroup) {
        formatGroup.push({
          produtos: [] as ItemPedido[],
          codigogrupo: '029',
          descrigrupo: 'SISTEMA DE PRESSURIZACAO',
        });
      }*/
      //END_TESTE
    }

    const formatGroupSort = formatGroup.sort((a, b) => {
      return a["codigogrupo"] > b["codigogrupo"] ? 1 : -1;
    });
    setGrupoProdutos(formatGroupSort);
    //return formatGroupSort;
  }

  function getQuantidadeItemsPedido(itens: ItemPedido[]) {
    let qtde = 0;

    itens.forEach((item: ItemPedido) => {
      qtde += item.quantidadeComprar;
    });

    return qtde;
  }

  function getValorTotalPedidoSemImpostos(itens: ItemPedido[]) {
    let valor = 0;

    itens.forEach((item: ItemPedido) => {
      valor += item.precoTotalSemImpostos;
    });

    return valor;
  }

  function getValorTotalPedidoComImpostos(itens: ItemPedido[]) {
    let valor = 0;

    itens.forEach((item: ItemPedido) => {
      valor += item.precoTotalComImpostos;
    });

    return valor;
  }

  function getValorTotalDescontos(itens: ItemPedido[]) {
    let valor = 0;

    itens.forEach((item: ItemPedido) => {
      const percentualDesconto = item.percentualDesconto || 0;
      const desconto = (item.precoPor / 100) * percentualDesconto;

      valor += desconto * item.quantidadeComprar;
    });

    return valor;
  }

  function getIpiTotalPedido(itens: ItemPedido[]) {
    let ipiTotal = 0;

    itens.forEach((item: ItemPedido) => {
      ipiTotal += item.ipi;
    });

    return ipiTotal;
  }

  function getStTotalPedido(itens: ItemPedido[]) {
    let stTotal = 0;

    itens.forEach((item: ItemPedido) => {
      stTotal += item.st;
    });

    return stTotal;
  }

  function getCanShowImpostosCalculados(itens: ItemPedido[]) {
    return itens.every((item) => item.impostoCalculado === true);
  }

  function getFrete(
    itens: ItemPedido[]
  ): { tipo: "CIF" | "FOB"; valor: number } {
    const tipo = user?.revendedor?.tipoFrete === "FOB" ? "FOB" : "CIF";

    return { tipo, valor: 0 };

    /** JL-HF_19-10-2021  Remover calculo frete (Solicitado por Douglas Chagas) */
    /*
    if (itens.length === 0) {
      return { tipo: 'CIF', valor: 0 };
    }

    const regioes = {
      norte: ['AC', 'AP', 'AM', 'PA', 'RO', 'RR', 'TO'],
      nordeste: ['AL', 'BA', 'CE', 'MA', 'PB', 'PE', 'PI', 'RN', 'SE'],
      centroOeste: ['GO', 'MT', 'MS'],
      sudeste: ['ES', 'MG', 'RJ', 'SP'],
      sul: ['PR', 'SC', 'RS'],
    };
    const valorTotalSemImpostos = getValorTotalPedidoSemImpostos(itens);

    // Se o pedido contiver produtos FOB, retornar FOB
    if (itens.some((item) => item.produto.tipoFrete === 'FOB')) {
      return { tipo: 'FOB', valor: 0 };
    }

    // Se o cliente for FOB, retornar FOB
    if (user?.revendedor?.tipoFrete === 'FOB') {
      return { tipo: 'FOB', valor: 0 };
    }

    // Se Valor Total do Pedido > R$ 2.000,00 (dois mil) retornar CIF
    if (valorTotalSemImpostos > 2000) {
      return { tipo: 'CIF', valor: 0 };
    }

    if (user?.revendedor?.uf) {
      const userUF = user?.revendedor?.uf.toUpperCase();

      // Se o cliente for da Regiões Sul e Sudeste, retornar R$ 50,00
      if (regioes.sul.includes(userUF) || regioes.sudeste.includes(userUF)) {
        return { tipo: 'CIF', valor: 50 };
      }

      // Se o cliente for da Regiões Centro Oeste, Norte ou Nordeste, retornar R$ 80,00
      if (regioes.centroOeste.includes(userUF) || regioes.norte.includes(userUF) || regioes.nordeste.includes(userUF)) {
        return { tipo: 'CIF', valor: 80 };
      }
    }

    return { tipo: 'CIF', valor: 0 };
    */
  }

  function resetImpostoCalculado(itens: ItemPedido[]) {
    return itens.map((item) => {
      return {
        ...item,
        impostoCalculado: false,
      };
    });
  }

  async function handleChangeCondicaoPagamento(value: string) {
    setCondicaoPagamento(value);

    const loader = showLoader(
      "Por favor aguarde. Esta tarefa pode demorar vários segundos. Atualizando item(s) do pedido."
    );

    try {
      const itens = items
        .filter((i) => i.quantidadeComprar > 0)
        .map((item) => ({
          codigo: item.produto.codigo,
          quantidade: item.quantidadeComprar,
          percentualDesconto: item.percentualDesconto || 0,
        }));

      const response = await updateItems({
        condicaoPagamento: value,
        itens,
        calcularImpostos: "false",
        calcularEntregas: "false",
        calcularQuantidadeMes: "false",
      });

      if (response.data) {
        let merged = response.data.map((data: ItemPedido) => {
          const currentItem = items.find(
            (item) => item.produto.codigo === data.produto.codigo
          );

          if (currentItem) {
            // altera todas as propriedades do itemPedido, menos as referentes a quantidade sugerida e datas de entrega
            return {
              ...data,
              quantidadeSugerida: currentItem.quantidadeSugerida,
              quantidadeMes: currentItem.quantidadeMes,
              entrega1Data: currentItem.entrega1Data,
              entrega2Data: currentItem.entrega2Data,
              entrega1Quantidade: currentItem.entrega1Quantidade,
              entrega2Quantidade: currentItem.entrega2Quantidade,
              percentualDesconto: currentItem.percentualDesconto || 0,
              _keepQuantidadeComprar:
                currentItem._keepQuantidadeComprar || false,
            };
          }

          return data;
        });

        merged = resetImpostoCalculado(merged);

        merged = transformItems(merged);

        setItems(merged);
      }

      hideLoader(loader);
    } catch (error) {
      Swal.fire({
        icon: "warning",
        title: "Erro",
        text:
          "Ocorreu um erro no processamento da requisição. Por favor, tente novamente mais tarde.",
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonText: "OK",
      });

      console.log(error);
    }
  }

  async function handleItemChangeQuantidade(
    itemPedido: ItemPedido,
    novaQuantidade: number
  ) {
    if (itemPedido) {
      const loader = showLoader(
        "Por favor aguarde. Esta tarefa pode demorar vários segundos. Atualizando item do pedido."
      );

      try {
        //Validação para quantidade de produtos Add - JL_09-2021
        itemPedido.quantidadeComprar =
          itemPedido.quantidadeComprar > 0
            ? itemPedido.quantidadeComprar
            : novaQuantidade;

        const response = await updateItems({
          condicaoPagamento,
          itens: [
            {
              codigo: itemPedido.produto.codigo,
              quantidade: novaQuantidade,
              percentualDesconto: itemPedido.percentualDesconto || 0,
            },
          ],
          calcularImpostos: "false",
          calcularEntregas: "true",
          calcularQuantidadeMes: "false",
        });

        if (response.data) {
          // altera todas as propriedades do itemPedido, menos as referentes a quantidade sugerida
          itemPedido = Object.assign({}, itemPedido, response.data[0], {
            impostoCalculado: false,
            quantidadeSugerida: itemPedido.quantidadeSugerida,
            quantidadeMes: itemPedido.quantidadeMes,
            _keepQuantidadeComprar: novaQuantidade > 0,
          });

          const _items = resetImpostoCalculado(items);
          const index = items.findIndex(
            (item) => item.produto.codigo === itemPedido.produto.codigo
          );

          _items[index] = itemPedido;

          const merged = transformItems(_items);

          setItems(merged);
        }

        hideLoader(loader);
      } catch (error) {
        Swal.fire({
          icon: "warning",
          title: "Erro",
          text:
            "Ocorreu um erro no processamento da requisição. Por favor, tente novamente mais tarde.",
          allowEscapeKey: false,
          allowOutsideClick: false,
          confirmButtonText: "OK",
        });

        console.log(error);
      }
    }
  }

  function handleShowDetalheProduto(itemPedido: ItemPedido) {
    const ReactSwal = withReactContent(Swal);

    ReactSwal.fire({
      title: "Detalhe do produto",
      html: <ProdutoDetail produto={itemPedido.produto} />,
      width: "800px",
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      showCloseButton: true,
    });
  }

  async function handleShowHistoricoItem(itemPedido: ItemPedido) {
    try {
      const loader = showLoader("Carregando histórico de compra do produto.");
      const response = await fetchHistoricoCompraProduto(
        itemPedido.produto.codigo
      );

      hideLoader(loader);

      if (response.data) {
        const ReactSwal = withReactContent(Swal);

        ReactSwal.fire({
          title: "Histórico de compra do produto",
          html: (
            <ProdutoHistory
              entries={response.data}
              quantidadeSugerida={itemPedido.quantidadeSugerida}
            />
          ),
          width: "800px",
          allowEscapeKey: false,
          allowOutsideClick: false,
          showConfirmButton: false,
          showCloseButton: true,
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "warning",
        title: "Erro",
        text:
          "Ocorreu um erro no processamento da requisição. Por favor, tente novamente mais tarde.",
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonText: "OK",
      });

      console.log(error);
    }
  }

  function handleShowDetalheItem(itemPedido: ItemPedido) {
    const ReactSwal = withReactContent(Swal);

    itemPedido.condicaoPagamento = `${condicaoPagamento} - ${
      condicoesPagamento.find((cp) => cp.codigo === condicaoPagamento)
        ?.descricao
    }`;

    ReactSwal.fire({
      title: "Detalhes do item do pedido",
      html: <ItemPedidoDetail itemPedido={itemPedido} />,
      width: "800px",
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      showCloseButton: true,
    });
  }

  async function handleAddDescontoItem(itemPedido: ItemPedido) {
    const ReactSwal = withReactContent(Swal);

    async function handleConfirm({
      percentualDesconto,
    }: {
      percentualDesconto: number;
    }) {
      const loader = showLoader(
        "Por favor aguarde. Esta tarefa pode demorar vários segundos. Simulando cálculo dos impostos."
      );

      itemPedido = await Object.assign({}, itemPedido, { percentualDesconto });

      const _items = await resetImpostoCalculado(items);
      const index = await items.findIndex(
        (item) => item.produto.codigo === itemPedido.produto.codigo
      );

      _items[index] = await itemPedido;

      const merged = await transformItems(_items);

      //setItems(merged);

      const checkDesconto =
        percentualDesconto > 0 &&
        user?.tipo.id === IdTipoUsuario.CLIENTE &&
        state?.orcamentoPedido.status !== 4;

      setIsDisabledConfirmaPedido(checkDesconto);

      await handleCalculateImpostos(merged);

      hideLoader(loader);

      ReactSwal.close();
    }

    ReactSwal.fire({
      title: "Atenção",
      html: (
        <ItemPedidoDesconto
          itemPedido={itemPedido}
          onConfirm={({ percentualDesconto }) =>
            handleConfirm({ percentualDesconto })
          }
          onCancel={() => ReactSwal.close()}
        />
      ),
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      showCloseButton: true,
    });
  }

  async function removeCheckedItems() {
    const response = await Swal.fire({
      title: "Atenção!",
      text: `Deseja remover ${
        checkedItems.length > 1 ? "estes itens" : "este item"
      } do pedido?`,
      icon: "warning",
      showCancelButton: true,
      allowEscapeKey: false,
      allowOutsideClick: false,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
    });

    if (response.isConfirmed) {
      const filtered = items.filter(
        (v) => !checkedItems.includes(v.produto.codigo)
      );

      let merged = resetImpostoCalculado(filtered);

      merged = transformItems(merged);

      setItems(merged);
      setCheckedItems([]);
    }
  }

  async function handleRemoveRow(item: ItemPedido) {
    const response = await Swal.fire({
      title: "Atenção!",
      text: "Deseja remover este item do pedido?",
      icon: "warning",
      showCancelButton: true,
      allowEscapeKey: false,
      allowOutsideClick: false,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
    });

    if (response.isConfirmed) {
      const filtered = items.filter(
        (v) => v.produto.codigo !== item.produto.codigo
      );
      let merged = resetImpostoCalculado(filtered);

      merged = transformItems(merged);

      setItems(merged);
    }
  }

  function handleShowBuscaProduto(getProduto?: string, getGrupo?: string) {
    const ReactSwal = withReactContent(Swal);

    /*async function handleSelect(produto: Produto) {
      ReactSwal.close();

      try {
        const exists = items.find((item) => item.produto.codigo === produto.codigo);

        if (exists) {
          Swal.fire({
            icon: 'warning',
            title: 'Produto duplicado',
            text: 'O produto selecionado já está na lista de pedidos!',
            allowEscapeKey: false,
            allowOutsideClick: false,
            confirmButtonText: 'OK',
          });

          return;
        }

        if (produto.codigo) {
          const loader = showLoader('Adicionando item ao pedido.');

          const response = await updateItems({
            condicaoPagamento,
            itens: [
              {
                codigo: produto.codigo,
                quantidade: 1,
                percentualDesconto: 0,
              },
            ],
            calcularImpostos: 'false',
            calcularEntregas: 'true',
            calcularQuantidadeMes: 'true',
          });

          if (response.data) {
            const itemPedido = response.data[0];
            const precoTotal = itemPedido.precoPor * 1;
            const precoTotalFormatado = precoTotal.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            });

            itemPedido.precoTotal = precoTotal;
            itemPedido.precoTotalFormatado = precoTotalFormatado;

            let merged = resetImpostoCalculado([...items, { ...itemPedido }]);

            merged = transformItems(merged);

            setItems(merged);
          }

          hideLoader(loader);
        }
      } catch (error) {
        Swal.fire({
          icon: 'warning',
          title: 'Erro',
          text: 'Ocorreu um erro no processamento da requisição. Por favor, tente novamente mais tarde.',
          allowEscapeKey: false,
          allowOutsideClick: false,
          confirmButtonText: 'OK',
        });

        console.log(error);
      }
    } */
    /*JL-HF_2022-06 ADD Lista de produtos */
    async function handleSelect(produtos: Produto[]) {
      ReactSwal.close();

      let exists;
      let produtoExists: any;

      try {
        produtos.map((produto: Produto) => {
          exists = items.find((item) => item.produto.codigo === produto.codigo);
          if (exists) {
            produtoExists = produto;
          }
          return false;
        });

        if (produtoExists) {
          Swal.fire({
            icon: "warning",
            title: "Produto duplicado",
            text: `O produto "${produtoExists?.titulo}" selecionado já está na lista de pedidos!`,
            allowEscapeKey: false,
            allowOutsideClick: false,
            confirmButtonText: "OK",
          });

          return;
        }

        let contProdutos = items.length + produtos.length;
        let produtoItens = items;

        produtos.map(async (produto: Produto) => {
          const loader = showLoader("Adicionando item ao pedido.");
          if (produto.codigo) {
            const response = await updateItems({
              condicaoPagamento,
              itens: [
                {
                  codigo: produto.codigo,
                  quantidade: 1,
                  percentualDesconto: 0,
                },
              ],
              calcularImpostos: "false",
              calcularEntregas: "true",
              calcularQuantidadeMes: "true",
            });

            if (response.data) {
              const itemPedido = response.data[0];
              const precoTotal = itemPedido.precoPor * 1;
              const precoTotalFormatado = precoTotal.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              });

              itemPedido.precoTotal = precoTotal;
              itemPedido._keepQuantidadeComprar = true;
              itemPedido.precoTotalFormatado = precoTotalFormatado;

              let merged = resetImpostoCalculado([
                ...produtoItens,
                { ...itemPedido },
              ]);
              merged = transformItems(merged);
              produtoItens = merged;

              if (contProdutos === produtoItens.length) {
                setItems(merged);
              }
            }
          }
          hideLoader(loader);
        });
      } catch (error) {
        Swal.fire({
          icon: "warning",
          title: "Erro",
          text:
            "Ocorreu um erro no processamento da requisição. Por favor, tente novamente mais tarde.",
          allowEscapeKey: false,
          allowOutsideClick: false,
          confirmButtonText: "OK",
        });

        console.log(error);
      }
    }

    ReactSwal.fire({
      title: "Busca de produtos",
      html: (
        <ProdutoSearch
          onSelect={(produto) => handleSelect(produto)}
          searchText={getProduto || ""}
          grupo={getGrupo || ""}
        />
      ),
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      showCloseButton: true,
      width: "960px",
    });
  }

  async function handleAddPromocoes() {
    try {
      const loader = showLoader("Carregando itens em promoção.");

      const response = await fetchPromocoes({
        condicaoPagamento,
        calcularImpostos: "false",
        calcularEntregas: "true",
        calcularQuantidadeMes: "true",
      });

      if (response.data) {
        const { produtos } = response.data;

        if (produtos.length === 0) {
          Swal.fire({
            icon: "info",
            text: "Não há itens em promoção no momento.",
            allowEscapeKey: false,
            allowOutsideClick: false,
            confirmButtonText: "OK",
          });

          return;
        }

        const _items = transformItems(produtos);

        const filtered = _items.filter((_item) => {
          const presentInGrid = items.find(
            (item) => item.produto.codigo === _item.produto.codigo
          );

          return !presentInGrid;
        });

        let merged = resetImpostoCalculado([...items, ...filtered]);

        merged = transformItems(merged);

        setItems(merged);
      }

      hideLoader(loader);
    } catch (error) {
      Swal.fire({
        icon: "warning",
        title: "Erro",
        text:
          "Ocorreu um erro no processamento da requisição. Por favor, tente novamente mais tarde.",
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonText: "OK",
      });

      console.log(error);
    }
  }
  /*JL-HF_2023-12| Ajuste Realizado para receber parâmetro GETITEMS para calcular automático de impostos ao adicionar desconto
   * Antes função não recebia parâmetro dados calculados da variável ITEMS
   * Solicitante: Douglas Chagas
   */
  async function handleCalculateImpostos(getItems: any) {
    try {
      const loader = showLoader(
        "Por favor aguarde. Esta tarefa pode demorar vários segundos. Simulando cálculo dos impostos."
      );

      const itens = await getItems
        .filter((i: any) => i.quantidadeComprar > 0) //2023-12 | SOLICITADO DOUGLAS CHAGAS
        .map((item: any) => ({
          codigo: item.produto.codigo,
          quantidade: item.quantidadeComprar,
          percentualDesconto: item.percentualDesconto || 0,
        }));

      const response = await updateItems({
        condicaoPagamento,
        itens,
        calcularImpostos: "true",
        calcularEntregas: "true", //Alterado para TRUE solicitado por Doulgas Chagas 2023-12
        calcularQuantidadeMes: "false",
      });

      if (response.data) {
        let merged = response.data.map((data: ItemPedido) => {
          const currentItem = getItems.find(
            (item: any) => item.produto.codigo === data.produto.codigo
          );

          if (currentItem) {
            return {
              ...data,
              impostoCalculado: true,
              quantidadeSugerida: currentItem.quantidadeSugerida,
              quantidadeMes: currentItem.quantidadeMes,
              entrega1Data: currentItem.entrega1Data,
              entrega2Data: currentItem.entrega2Data,
              entrega1Quantidade: currentItem.entrega1Quantidade,
              entrega2Quantidade: currentItem.entrega2Quantidade,
              percentualDesconto: currentItem.percentualDesconto || 0,
              _keepQuantidadeComprar:
                currentItem._keepQuantidadeComprar || false,
            };
          }

          return {
            ...data,
            impostoCalculado: true,
          };
        });

        merged = transformItems(merged);

        setItems(merged);
      }

      hideLoader(loader);
    } catch (error) {
      Swal.fire({
        icon: "warning",
        title: "Erro",
        text:
          "Ocorreu um erro no processamento da requisição. Por favor, tente novamente mais tarde.",
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonText: "OK",
      });

      console.log(error);
    }
  }

  async function handleConfirmarPedido() {
    const ReactSwal = withReactContent(Swal);

    const condPag = `${condicaoPagamento} - ${
      condicoesPagamento.find((cp) => cp.codigo === condicaoPagamento)
        ?.descricao
    }`;

    async function handleOrcamentoInteracao(id: string) {
      try {
        await gravarOrcamentoInteracao({
          id,
          status: 5, //EFETIVADO
          justificativa: "Orçamento aprovado e pedido efetivado com sucesso.",
        });
      } catch (error) {
        console.log(error);
      }
    }

    function handleConfirm(response: AxiosResponse) {
      ReactSwal.close();

      if (String(response.data.pedidoPai).trim().length <= 0) {
        throw Error();
      }

      if (
        state?.isOrcamento &&
        state?.orcamentoPedido?.status === 4 &&
        Number(state.orcamentoPedido?.id) > 0
      ) {
        handleOrcamentoInteracao(state.orcamentoPedido?.id);
      }

      const pedidoSaldo =
        String(response.data.pedidoSaldo).trim().length > 0
          ? `<p><b>Pedido Saldo:</b> ${response.data.pedidoSaldo}</p>`
          : "";

      Swal.fire({
        title: "Pedido enviado com sucesso!",
        html: `<div>
                <p>Em breve você receberá um e-mail automático de confirmação com os detalhes para seu acompanhamento.</p>
                <p><b>Pedido:</b> ${response.data.pedidoPai}</p>
                ${pedidoSaldo}
                <h3>OBRIGADO!</h3>
              </div>`,
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonText: "OK",
        onClose: () => {
          confirmLeave.current = true;
          //setLoadSaldoMeta(true); //JL_2021-06-09
          history.push("/");
        },
      });
    }

    function handleCancel() {
      ReactSwal.close();
    }

    try {
      const loader = showLoader(
        "Por favor aguarde. Esta tarefa pode demorar vários segundos. Preparando confirmação do pedido."
      );

      const itens = items.map((item) => ({
        codigo: item.produto.codigo,
        quantidade: item.quantidadeComprar,
        percentualDesconto: item.percentualDesconto || 0,
      }));

      const promTransportadoras = fetchTransportadoras();
      const promCalculoImpostos = updateItems({
        condicaoPagamento,
        itens,
        calcularImpostos: "true",
        calcularEntregas: "true",
        calcularQuantidadeMes: "false",
      });

      const [resTransportadoras, resCalculoImpostos] = await Promise.all([
        promTransportadoras,
        promCalculoImpostos,
      ]);

      hideLoader(loader);

      if (resTransportadoras.data && resCalculoImpostos.data) {
        let merged = resCalculoImpostos.data.map((data: ItemPedido) => {
          const currentItem = items.find(
            (item) => item.produto.codigo === data.produto.codigo
          );

          if (currentItem) {
            return {
              ...data,
              impostoCalculado: true,
              percentualDesconto: currentItem.percentualDesconto || 0,
              quantidadeSugerida: currentItem.quantidadeSugerida,
              quantidadeMes: currentItem.quantidadeMes,
              entrega1Data: currentItem.entrega1Data,
              entrega2Data: currentItem.entrega2Data,
              entrega1Quantidade: currentItem.entrega1Quantidade,
              entrega2Quantidade: currentItem.entrega2Quantidade,
              _keepQuantidadeComprar:
                currentItem._keepQuantidadeComprar || false,
            };
          }

          return data;
        });

        merged = transformItems(merged);

        setItems(merged);

        ReactSwal.fire({
          title: "Confirmação - Pedido",
          html: (
            <PedidoResumo
              transportadoras={resTransportadoras.data.items}
              quantidadeItens={getQuantidadeItemsPedido(merged)}
              condicaoPagamento={condPag}
              valorTotalSemImpostos={getValorTotalPedidoSemImpostos(merged)}
              valorTotalComImpostos={getValorTotalPedidoComImpostos(merged)}
              valorTotalDescontos={getValorTotalDescontos(merged)}
              ipiTotal={getIpiTotalPedido(merged)}
              stTotal={getStTotalPedido(merged)}
              frete={getFrete(merged)}
              pedidoTipo={"REPOSICAO"}
              itens={merged}
              user={user}
              onConfirm={(response) => handleConfirm(response)}
              onCancel={() => handleCancel()}
            />
          ),
          width: "600px",
          allowEscapeKey: false,
          allowOutsideClick: false,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "warning",
        title: "Erro",
        text:
          "Ocorreu um erro no processamento da requisição. Por favor, tente novamente mais tarde.",
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonText: "OK",
      });

      console.log(error);
    }
  }

  function showModalSaida(pathname: string) {
    const ReactSwal = withReactContent(Swal);

    if (confirmLeave.current) {
      return true;
    }

    function handleStay() {
      ReactSwal.close();
    }

    function handleLeave() {
      ReactSwal.close();

      confirmLeave.current = true;

      history.push({ pathname });
    }

    ReactSwal.fire({
      title: "Você já está indo embora?",
      html: (
        <ModalSaida onStay={() => handleStay()} onLeave={() => handleLeave()} />
      ),
      width: "600px",
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
    });

    return false;
  }

  //JL-HF_2022-06
  async function handleSaveOrcamento() {
    const ReactSwal = withReactContent(Swal);

    const condPag = `${condicaoPagamento} - ${
      condicoesPagamento.find((cp) => cp.codigo === condicaoPagamento)
        ?.descricao
    }`;

    function handleCancel() {
      ReactSwal.close();
    }

    try {
      showLoader(
        "Por favor aguarde. Esta tarefa pode demorar vários segundos. Preparando confirmação do pedido."
      );

      const itens = await items.map((item) => ({
        codigo: item.produto.codigo,
        quantidade: item.quantidadeComprar,
        percentualDesconto: item.percentualDesconto || 0,
      }));

      const promCalculoImpostos = await updateItems({
        condicaoPagamento,
        itens,
        calcularImpostos: "true",
        calcularEntregas: "true",
        calcularQuantidadeMes: "false",
      });

      const [resCalculoImpostos] = await Promise.all([promCalculoImpostos]);

      if (resCalculoImpostos.data) {
        let merged = resCalculoImpostos.data.map((data: ItemPedido) => {
          const currentItem = items.find(
            (item) => item.produto.codigo === data.produto.codigo
          );

          if (currentItem) {
            return {
              ...data,
              impostoCalculado: true,
              percentualDesconto: currentItem.percentualDesconto || 0,
              quantidadeSugerida: currentItem.quantidadeSugerida,
              quantidadeMes: currentItem.quantidadeMes,
              entrega1Data: currentItem.entrega1Data,
              entrega2Data: currentItem.entrega2Data,
              entrega1Quantidade: currentItem.entrega1Quantidade,
              entrega2Quantidade: currentItem.entrega2Quantidade,
              _keepQuantidadeComprar:
                currentItem._keepQuantidadeComprar || false,
            };
          }

          return data;
        });

        merged = transformItems(merged);

        setItems(merged);

        //hideLoader(loader);

        ReactSwal.fire({
          title: "Confirmação - Orçamento",
          html: (
            <OrcamentoResumo
              quantidadeItens={getQuantidadeItemsPedido(merged)}
              condicaoPagamento={condPag}
              valorTotalSemImpostos={getValorTotalPedidoSemImpostos(merged)}
              valorTotalComImpostos={getValorTotalPedidoComImpostos(merged)}
              valorTotalDescontos={getValorTotalDescontos(merged)}
              ipiTotal={getIpiTotalPedido(merged)}
              stTotal={getStTotalPedido(merged)}
              itens={merged}
              user={user}
              history={history}
              //onConfirm={(response) => handleConfirm(response)}
              onCancel={() => handleCancel()}
            />
          ),
          width: "600px",
          allowEscapeKey: false,
          allowOutsideClick: false,
          showConfirmButton: false,
        });

        //return;
      }

      //hideLoader(loader);
    } catch (error) {
      Swal.fire({
        icon: "warning",
        title: "Erro",
        text:
          "Ocorreu um erro no processamento da requisição. Por favor, tente novamente mais tarde.",
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonText: "OK",
      });

      console.log(error);
    }
  }

  /**Display para expandir lista de produtos */
  function handleIsExpandIcon(e: MouseEvent<HTMLButtonElement>, grup: string) {
    //e.preventDefault();
    setIsExpand(grup);

    if (isExpand === grup) {
      setIsOpen(!isOpen);

      return;
    }
    if (!isOpen && isExpand !== grup) {
      setIsOpen(!isOpen);
    }
    //onExpands(codGrupo);
  }

  /*
    const mergedOrderCod = merged.sort((a, b) => {
      return a['codigogrupo'] > b['codigogrupo'] ? 1 : -1;
    });
*/

  /* JL-HF_09-03-2023 Ordenar Itens produtos do grupo (Solicitante: Douglas Chagas / Otiniel)*/
  function sortItensProdutos(itens: ItemPedido[]) {
    const ordenar = itens.sort((a, b) => {
      return a.produto["titulo"] > b.produto["titulo"] ? 1 : -1;
    });

    return ordenar;
  }

  return (
    <div className="container-fluid my-4">
      <Prompt message={({ pathname }) => showModalSaida(pathname)} />

      <div className="alert alert-info">
        <div className="row align-items-center">
          <div className="col-md">
            <strong>Selecione a condição de pagamento:</strong>
          </div>
          <div className="col-md">
            <select
              className="form-control"
              id="condicaoPagamento"
              value={condicaoPagamento}
              onChange={(e) => handleChangeCondicaoPagamento(e.target.value)}
            >
              {condicoesPagamento.map((cp) => (
                <option key={cp.codigo} value={cp.codigo}>
                  {cp.codigo} - {cp.descricao}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className="alert alert-info">
        <div className="d-block d-md-flex align-items-center justify-content-between">
          <div className="flex-fill">
            <div>
              Os impostos serão calculados na confirmação do pedido ou, caso
              prefira, clique no botão
              <span className="d-inline d-md-none"> abaixo</span>
              <span className="d-none d-md-inline"> ao lado</span> para fazer
              uma simulação.
            </div>
            <div>
              O valor do frete se incorpora à base de cálculo dos impostos.
            </div>
          </div>

          <div className="text-center">
            <button
              type="button"
              className="btn btn-primary d-md-flex align-items-center mt-4 mt-md-0"
              onClick={() => handleCalculateImpostos(items)}
            >
              <IoIosCalculator className="mr-1" size={24} />
              Calcular impostos
            </button>
          </div>
        </div>
      </div>

      {checkedItems.length > 0 && (
        <button
          type="button"
          className="btn btn-danger d-inline-flex align-items-center mb-3"
          onClick={() => removeCheckedItems()}
        >
          <FiTrash style={{ marginRight: "5px" }} />
          Remover items selecionados
        </button>
      )}

      <div className="d-flex flex-column table-responsive">
        {items.length > 0 ? (
          <>
            {grupoProdutos.map((item) => (
              <div key={item.codigogrupo} className="">
                <div className="d-flex flex-fill bg-light border border-primary b justify-content-between align-items-center border-right border-primary p-2">
                  {/*<button
                    type="button"
                    onClick={() => showModelComentario()}
                    title="Deixe seu comentário ou justificativa para grupo produto ou produto"
                    className="toggle btn btn-sm btn-link d-flex flex-fill align-items-center justify-content-center  m-0 mt-1 mr-2"
                  >
                    <FaRegComment size={18} color={"##0ea5e9"} />
                  </button>*/}
                  <ButtonComentarioPedido
                    props={
                      "toggle btn btn-sm btn-link d-flex flex-fill align-items-center justify-content-center  m-0 mt-1 mr-2"
                    }
                    codigo={user!.revendedor!.codigo}
                    cnpj={user!.revendedor!.cnpj}
                    loja={user!.revendedor!.loja}
                    userTipo={user!.tipo!.nome}
                    userCodigo={user!.codigo}
                    grupo={{
                      grupo: item.codigogrupo,
                      descri_grupo: item.descrigrupo,
                    }}
                  />
                  <p className="col-md-11 d-flex flex-column mt-2 mb-0 ">
                    <strong>
                      GRUPO: {item.codigogrupo} -{" "}
                      {item.descrigrupo.toUpperCase()}
                    </strong>
                  </p>
                  {item.produtos.length > 0 ? (
                    <>
                      <button
                        type="button"
                        style={{ minWidth: 30, maxWidth: 30 }}
                        className="toggle btn btn-sm btn-link d-flex flex-fill align-items-center justify-content-center p-0 m-0 mt-1 mr-2"
                        data-tip={`${
                          isOpen
                            ? "Ocutar grupo produtos"
                            : "Expandir grupo produtos"
                        }`}
                        onClick={(e) => handleIsExpandIcon(e, item.codigogrupo)}
                      >
                        {isOpen && isExpand === item.codigogrupo ? (
                          <FaAngleUp size={24} color={"#dc3545"} />
                        ) : (
                          <FaAngleDown size={24} color={"#28a745"} />
                        )}
                      </button>
                    </>
                  ) : (
                    <button
                      type="button"
                      style={{ minWidth: 30, maxWidth: 30 }}
                      className="toggle btn btn-sm btn-link d-flex flex-fill align-items-center justify-content-center p-0 m-0 mt-1 mr-2"
                      data-tip={"Adicionar produtos a esse grupo"}
                      onClick={() =>
                        handleShowBuscaProduto(
                          item.descrigrupo,
                          item.codigogrupo
                        )
                      }
                    >
                      <FiPlusCircle size={24} className="btn-outline-primary" />
                    </button>
                  )}
                </div>

                <div
                  style={{
                    display: `${
                      isOpen && isExpand === item.codigogrupo ? "block" : "none"
                      // (isOpen && isExpand === item.codigogrupo) || group !== item.codigogrupo ? 'block' : 'none'
                    }`,
                    transitionTimingFunction: "ease-in",
                    transitionProperty: "all",
                    transitionDuration: `${isOpen ?? "0.5s"}`,
                    transitionDelay: `${isOpen ?? "0.5s ease-in"}`,
                  }}
                >
                  {/* {items.map((item, index) => ( */}
                  {sortItensProdutos(item.produtos).map((item, index) => (
                    <div key={index} className="p-0 m-0">
                      <RowPedido
                        key={`${item.produto.codigo}-${item.quantidadeComprar}`}
                        itemPedido={item}
                        onCheck={() =>
                          setCheckedItems([
                            ...checkedItems,
                            item.produto.codigo,
                          ])
                        }
                        onUncheck={() =>
                          setCheckedItems(
                            checkedItems.filter(
                              (ci) => ci !== item.produto.codigo
                            )
                          )
                        }
                        onShowDetalheProduto={() =>
                          handleShowDetalheProduto(item)
                        }
                        onShowHistoricoItem={() =>
                          handleShowHistoricoItem(item)
                        }
                        onShowDetalheItem={() => handleShowDetalheItem(item)}
                        onChangeQuantidade={(novaQuantidade) =>
                          handleItemChangeQuantidade(item, novaQuantidade)
                        }
                        onChangeDesconto={() => handleAddDescontoItem(item)}
                        onRemove={() => handleRemoveRow(item)}
                        //onExpands={() => handleIsExpandIcon(item.codigogrupo)}
                        //codGrupo={item.codigogrupo}
                        //setGrupo={isExpand}
                        //isOpen={isOpen}
                        // isVisible={
                        //   group !== item.codigogrupo &&
                        //   items.filter(
                        //     (cg) => cg.codigogrupo === item.codigogrupo
                        //   ).length > 1
                        // }
                      />
                      {/* {handleGroup(item.codigogrupo)} */}
                    </div>
                  ))}
                </div>
                {/* {handleGroup(item.codigogrupo)} */}
              </div>
            ))}
          </>
        ) : (
          <div className="alert alert-warning text-center">
            {somenteOfertas
              ? "Não há itens disponíveis em promoção."
              : "Não há itens disponíveis para o pedido."}
          </div>
        )}
      </div>

      {checkedItems.length > 0 && (
        <button
          type="button"
          className="btn btn-danger d-inline-flex align-items-center mt-3"
          onClick={() => removeCheckedItems()}
        >
          <FiTrash style={{ marginRight: "5px" }} />
          Remover items selecionados
        </button>
      )}

      <div className="d-block d-md-flex flex-fill justify-content-end my-4 offset-md-0">
        {/* JL-HF_19-10-2021  Remover calculo frete (Solicitado por Douglas Chagas)*/}
        <PedidoMessageFrete />

        <table className="table table-bordered border-dark align-self-end my-12 offset-md-12">
          <tbody>
            <tr>
              <td className="p-2 pr-4">Total da mercadoria</td>
              <td className="p-2">
                {getValorTotalPedidoSemImpostos(items).toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </td>
            </tr>
            <tr>
              <td className="p-2 pr-4">Total de IPI</td>
              <td className="p-2">
                {getCanShowImpostosCalculados(items)
                  ? getIpiTotalPedido(items).toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })
                  : "A calcular"}
              </td>
            </tr>
            <tr>
              <td className="p-2 pr-4">Total de ST</td>
              <td className="p-2">
                {getCanShowImpostosCalculados(items)
                  ? getStTotalPedido(items).toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })
                  : "A calcular"}
              </td>
            </tr>
            {/* <tr>
              <td className="p-2 pr-4">Frete</td>
              <td className="p-2">
                {getFrete(items).tipo}{' '}
                {getFrete(items).valor > 0 && (
                  <span>
                    {' '}
                    /{' '}
                    {getFrete(items).valor.toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </span>
                )}
              </td>
            </tr> */}
            <tr>
              <td className="p-2 pr-4">
                <strong>Total a pagar</strong>
              </td>
              <td className="p-2">
                <strong>
                  {getCanShowImpostosCalculados(items) ? (
                    <>
                      {getValorTotalPedidoComImpostos(items).toLocaleString(
                        "pt-BR",
                        {
                          style: "currency",
                          currency: "BRL",
                        }
                      )}
                    </>
                  ) : (
                    <>
                      {getValorTotalPedidoSemImpostos(items).toLocaleString(
                        "pt-BR",
                        {
                          style: "currency",
                          currency: "BRL",
                        }
                      )}
                    </>
                  )}
                </strong>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="d-block d-md-flex flex-fill mb-md-4">
        <div className="d-block d-md-flex flex-fill mr-md-2 ml-md-2 mb-4 mb-md-0">
          <button
            type="button"
            className="d-flex align-items-center justify-content-center btn btn-block btn-lg btn-outline-primary mt-0"
            onClick={() => handleShowBuscaProduto()}
          >
            <FiPlusCircle className="mr-2" />
            <span>Adicionar produto à lista</span>
          </button>
        </div>

        <div className="d-block d-md-flex flex-fill mr-md-2 ml-md-2 mb-4 mb-md-0">
          {!somenteOfertas && (
            <button
              type="button"
              className="d-flex align-items-center justify-content-center btn btn-block btn-lg btn-outline-primary mt-0"
              onClick={() => handleAddPromocoes()}
            >
              <FiGift className="mr-2" />
              <span>Adicionar promoções</span>
            </button>
          )}
        </div>

        {/* JL-HF_2022-06 */}
        <div className="d-block d-md-flex flex-fill mr-md-2 ml-md-2 mb-4 mb-md-0">
          <button
            type="button"
            className="d-flex align-items-center justify-content-center btn btn-block btn-lg btn-outline-warning mt-0"
            onClick={() => handleSaveOrcamento()}
          >
            <FiSave className="mr-2" />
            <span>Salvar orçamento</span>
          </button>
        </div>
        {/* END_JL-HF-2022-06 */}

        <div className="d-block d-md-flex flex-fill ml-md-2 ml-md-2 mb-4 mb-md-0">
          <button
            type="button"
            disabled={isDisabledConfirmaPedido}
            title={
              isDisabledConfirmaPedido
                ? "Só é permitido confirmar pedido após aprovação do orçamento."
                : ""
            }
            className="d-flex align-items-center justify-content-center btn btn-block btn-lg btn-outline-success mt-0"
            onClick={() => handleConfirmarPedido()}
          >
            <FiSend className="mr-2" />
            <span>Confirmar pedido</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Pedido;
